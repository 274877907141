.helperTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: -14px;
  span:first-child {
    color: var(--eds-color-text-danger);
    font: var(--eds-type-body-3);
  }
  span:nth-child(2) {
    color: var(--eds-color-text-secondary);
  }
}
