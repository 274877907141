.assetContainer > form > fieldset > div > div > div > div > div > div {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  fieldset,
  label,
  legend,
  li,
  p,
  span,
  strong,
  ul,
  input,
  table,
  tr,
  th,
  td,
  thead,
  tbody {
    font-family: monospace !important;
    font-size: 16;
  }
}
.assetContainer
  > form
  > fieldset
  > div
  > div:nth-child(3)
  > div
  > div:nth-child(1) {
  max-width: 740px;
  min-width: 500px;
  width: unset !important;
}
.assetContainer {
  display: flex;
  margin-bottom: 40px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.title {
  width: 80%;
}
